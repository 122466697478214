<template>
  <section >
    <h2 class="titulo-links-escolha">DELETAR ESPECIALIZAÇÃO DO PROFESSOR</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="sel1">Selecione o segmento:</label>
                    <select class="form-control" v-model="segmentoSelecionado"   @change="buscarDisciplinaALL(segmentoSelecionado)">
                      <option v-for="n in segmentoALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div> 
                  <div class="col-md-12"></div>
                  <div class="form-group col-md-6" v-if="disciplinaALL.length">
                    <label for="sel1">Selecione a disciplina:</label>
                    <select class="form-control" v-model="disciplinaSelecionada">
                      <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>    
                  <div class="flex">
                    <va-button class="mr-2 mb-2"  @click="deletar"> {{ $t('Deletar') }}</va-button>  
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>


import { Servidores } from "@/class/servidores.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
   id:{
    },
  },
  components: {
   
  },
  name: 'disciplina-Escolar',
  data () {
    return {
        segmentoSelecionado:'',
        segmentoALL:[],
        disciplinaSelecionada:'',
        disciplinaALL:[],
    }
  },
  methods: {
    async buscarSegmentoALL() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = data.data;
      
    },
    async buscarDisciplinaALL(segmentoSelecionado) {
      this.disciplinaALL = [];
      let data = await Servidores.visualizarEspecializaoProf(this.id);
      for (const el of data.data) {
        //mostrar disciplinas de 
        if (el.segmento_id != segmentoSelecionado)
          continue;
        let novo = {
          id : el.id,
          nome:null,
        }
        let data1 = await DisciplinaEscolar.obtemUm(el.disciplina_id);
        novo.nome = data1.data.nome; 
        this.disciplinaALL.push(novo);
      }
      if (!this.disciplinaALL.length) {
        this.$vaToast.init({
          message: "O segmento selecionado não possui disciplina para ser deletada!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          fullWidth: false,
        })
      }
    },
    async deletar() {
      try {
        const data = await Servidores.deletarEspecializacao(this.disciplinaSelecionada);
        this.$vaToast.init({
          message: "Deletado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({ name: "servidores" });
      }
      catch(e) {
        alert(e);
        this.$vaToast.init({
          message: "Erro ao deletar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'servidores'});
    }
    else {
      this.buscarSegmentoALL();
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
